<template>
    <div  v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="setting-container">
        <div class="setting-title">
            <div class="title">报价设置</div>
            <div class="tip">【公告】  订单报价=协议子账号日咨询量*协议子账号数量*客服类型*客服工作时长系数*[1+【（店铺数*淘系平台系数+店铺数*拼多多平台系数+店铺数*抖音平台系数）+(售前+售中+售后+附加类)】]*单个咨询量价格*服务天数</div>
        </div>
        <div class="setting-content">
            <div class="content-item">
                <div class="item-left">单个咨询量价格</div>
                <div class="item-right">
                    <div v-for="(item) in dictServicePrice['报价单配置']" :key="item.dataName + '-1'">
                        <span v-if="item.dataName == 'cost'" class="marginRight16">成本</span>
                        <span v-if="item.dataName == 'cost'"><el-input  type="number" @mousewheel.native.prevent class="item-input input-YUANTIAO" v-model="item.dataValue"></el-input></span>
                    </div>
                    <div class="marginTop30" v-for="(item) in dictServicePrice['报价单配置']" :key="item.dataName + '-2'">
                        <span v-if="item.dataName == 'offer'" class="marginRight16">报价</span>
                        <span v-if="item.dataName == 'offer'"><el-input type="number" @mousewheel.native.prevent class="item-input input-YUANTIAO" v-model="item.dataValue"></el-input></span>
                    </div>
                </div>
            </div>
            <div class="content-item">
                <div class="item-left">客服类型</div>
                <div class="item-right">
                    <div v-for="(item) in dictServicePrice['报价单配置']" :key="item.dataName + '-3'">
                        <span v-if="item.dataName == 'generalCustomerService'" class="marginRight16">普通客服</span>
                        <span v-if="item.dataName == 'generalCustomerService'"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="item.dataValue"></el-input></span>
                    </div>
                    <div class="marginTop30" v-for="(item) in dictServicePrice['报价单配置']" :key="item.dataName + '-4'">
                        <span v-if="item.dataName == 'goldCustomerService'" class="marginRight16">金牌客服</span>
                        <span v-if="item.dataName == 'goldCustomerService'"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="item.dataValue"></el-input></span>
                    </div>
                    <div class="marginTop30" v-for="(item) in dictServicePrice['报价单配置']" :key="item.dataName + '-9'">
                        <span v-if="item.dataName == 'promoteCustomerService'" class="marginRight16">临时客服</span>
                        <span v-if="item.dataName == 'promoteCustomerService'"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="item.dataValue"></el-input></span>
                    </div>
                </div>
            </div>
            <div class="content-item">
                <div class="item-left">客服工作时长</div>
                <div class="item-right">
                    <div v-for="(item) in dictServicePrice['报价单配置']" :key="item.dataName + '-5'">
                        <span v-if="item.dataName == 'eightHour'" class="marginRight16">8小时</span>
                        <span v-if="item.dataName == 'eightHour'"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="item.dataValue"></el-input></span>
                    </div>
                    <div class="marginTop30" v-for="(item) in dictServicePrice['报价单配置']" :key="item.dataName + '-6'">
                        <span v-if="item.dataName == 'sixteenHour'" class="marginRight16">16小时</span>
                        <span v-if="item.dataName == 'sixteenHour'"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="item.dataValue"></el-input></span>
                    </div>
                    <div v-for="(item) in dictServicePrice['报价单配置']" :key="item.dataName + '-7'">
                        <span v-if="item.dataName == 'addHour'" class="marginRight16">增加1小时</span>
                        <span v-if="item.dataName == 'addHour'"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="item.dataValue"></el-input><span class="grey-tip">( 非正常工作时间 )</span></span>
                        
                    </div>
                    <div class="marginTop30" v-for="(item) in dictServicePrice['报价单配置']" :key="item.dataName + '-8'">
                        <span v-if="item.dataName == 'reduceHour'" class="marginRight16">减少一小时</span>
                        <span v-if="item.dataName == 'reduceHour'"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="item.dataValue"></el-input><span class="grey-tip">( 正常工作时间 )</span></span>
                        
                    </div>
                </div>
            </div>
            <div class="content-item">
                <div class="item-left">约定数据标准</div>
                <div class="item-right">
                    <div class="platformBtn" @click="() => { addPlatformShow() }">平台设置</div>
                    <div>
                        <div class="platform-item" :style="moreVisib && index >= 2 ? 'display: none' : ''" v-for="(item, key, index) in dictPlatform" :key="key">
                            <div v-show="index < 2">
                                <div class="platformItem-title"><span>{{key}}</span></div>
                                <div class="platform-content">
                                    <div class="platform-row">
                                        <div class="row-item"></div>
                                        <div class="row-item">【基础版】</div>
                                        <div class="row-item">【专业版】</div>
                                        <div class="row-item">【旗舰版】</div>
                                    </div>
                                    <div class="platform-row" v-for="(itemTwo, index) in item" :key="index">
                                        <div class="row-item">{{itemTwo.dataValue.name}}</div>
                                        <div v-if="itemTwo.dataValue.name == '单个客服最大账号数'" class="row-item"></div>
                                        <div v-if="itemTwo.dataValue.name == '单个客服最大账号数'" class="row-item"></div>
                                        <div v-if="itemTwo.dataValue.name != '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent :class="(itemTwo.dataValue.name == '转换率') || (itemTwo.dataValue.name == '三分钟回复率') || (itemTwo.dataValue.name == '五分钟回复率') ? 'input-RATIO' : ''" class="item-input" v-model="itemTwo.dataValue.base"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name != '单个客服最大账号数' && itemTwo.dataValue.name != '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent :class="(itemTwo.dataValue.name == '转换率') || (itemTwo.dataValue.name == '三分钟回复率') || (itemTwo.dataValue.name == '五分钟回复率') ? 'input-RATIO' : ''" class="item-input" v-model="itemTwo.dataValue.register"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name != '单个客服最大账号数' && itemTwo.dataValue.name != '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent :class="(itemTwo.dataValue.name == '转换率') || (itemTwo.dataValue.name == '三分钟回复率') || (itemTwo.dataValue.name == '五分钟回复率') ? 'input-RATIO' : ''" class="item-input" v-model="itemTwo.dataValue.notice"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name == '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent class="item-input input-blue" v-model="itemTwo.dataValue.base"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name == '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent class="item-input input-blue" v-model="itemTwo.dataValue.register"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name == '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent class="item-input input-blue" v-model="itemTwo.dataValue.notice"></el-input></div>
                                    </div>
                                </div>
                            </div>
                            <div v-show="!moreVisib && index >= 2">
                                <div class="platformItem-title"><span>{{key}}</span></div>
                                <div class="platform-content">
                                    <div class="platform-row">
                                        <div class="row-item"></div>
                                        <div class="row-item">【基础版】</div>
                                        <div class="row-item">【专业版】</div>
                                        <div class="row-item">【旗舰版】</div>
                                    </div>
                                    <div class="platform-row" v-for="(itemTwo, index) in item" :key="index">
                                        <div class="row-item">{{itemTwo.dataValue.name}}</div>
                                        <div v-if="itemTwo.dataValue.name == '单个客服最大账号数'" class="row-item"></div>
                                        <div v-if="itemTwo.dataValue.name == '单个客服最大账号数'" class="row-item"></div>
                                        <div v-if="itemTwo.dataValue.name != '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent :class="(itemTwo.dataValue.name == '转换率') || (itemTwo.dataValue.name == '三分钟回复率') || (itemTwo.dataValue.name == '五分钟回复率') ? 'input-RATIO' : ''" class="item-input" v-model="itemTwo.dataValue.base"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name != '单个客服最大账号数' && itemTwo.dataValue.name != '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent :class="(itemTwo.dataValue.name == '转换率') || (itemTwo.dataValue.name == '三分钟回复率') || (itemTwo.dataValue.name == '五分钟回复率') ? 'input-RATIO' : ''" class="item-input" v-model="itemTwo.dataValue.register"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name != '单个客服最大账号数' && itemTwo.dataValue.name != '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent :class="(itemTwo.dataValue.name == '转换率') || (itemTwo.dataValue.name == '三分钟回复率') || (itemTwo.dataValue.name == '五分钟回复率') ? 'input-RATIO' : ''" class="item-input" v-model="itemTwo.dataValue.notice"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name == '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent class="item-input input-blue" v-model="itemTwo.dataValue.base"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name == '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent class="item-input input-blue" v-model="itemTwo.dataValue.register"></el-input></div>
                                        <div v-if="itemTwo.dataValue.name == '系数'" class="row-item"><el-input type="number" @mousewheel.native.prevent class="item-input input-blue" v-model="itemTwo.dataValue.notice"></el-input></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="seleteMore" @click="() => { moreVisib = false }" v-if="moreVisib"><span>查看更多平台</span><img src="../../assets/images/more_down.png"></div>
                    <div class="seleteMore" @click="() => { moreVisib = true }" v-if="!moreVisib"><span style="margin-left: 40px">收起</span><img src="../../assets/images/more_up.png"></div>
                </div>
            </div>
            <div class="content-item">
                <div class="item-left">服务内容</div>
                <div class="item-right">
                    <div>
                        <div class="platform-item" v-for="(item, key) in dictService" :key="key">
                            <div class="platformItem-title" style="justify-content: left;"><span>{{key}}</span></div>
                            <div class="platform-content">
                                <div class="platform-row">
                                    <div class="row-item">目录</div>
                                    <div class="row-item">基础功能</div>
                                    <div class="row-item">登记</div>
                                    <div class="row-item">通知</div>
                                </div>
                                <div class="platform-row" v-for="(itemTwo, index) in item" :key="index">
                                    <div class="row-item">{{itemTwo.dataValue.name}}</div>
                                    <div class="row-item">{{itemTwo.dataValue.base}}</div>
                                    <!-- <div class="row-item"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="itemTwo.dataValue.base"></el-input></div> -->
                                    <div class="row-item"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="itemTwo.dataValue.register"></el-input></div>
                                    <div class="row-item"><el-input type="number" @mousewheel.native.prevent class="item-input" v-model="itemTwo.dataValue.notice"></el-input></div>
                                </div>
                                <!-- <div v-if="key == '【售中】服务内容'">
                                    <div class="platform-row" v-for="(itemTwo, index) in 7" :key="index">
                                        <div class="row-item"></div>
                                    </div>
                                </div>
                                <div v-if="key == '【附加类】服务内容'">
                                     <div class="platform-row" v-for="(itemTwo, index) in 5" :key="index">
                                        <div class="row-item"></div>
                                    </div>
                                </div> -->
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="testBtn" @click="() => { showtestDialog() }">测试估价</div>
        </div>
        <div class="setting-bottom">
            <el-button class="common-screen-btn" type="primary" @click="() => { commitDialog = true }">确 定</el-button>
            <el-button class="common-screen-btn" @click="() => { resetDialog = true }">返回报价中心</el-button>
        </div>
        <el-dialog v-dialogDrag title="平台设置" :append-to-body="true" width="530px" :visible.sync="addPlatformDialog" :close-on-click-modal="false">
            
            <el-form :model="rulePlatform" :rules="rulePlatformRules" ref="rulePlatform" label-width="110px" class="demo-ruleForm">
                <el-form-item label="新增平台：" prop="platformName">
                    <el-input class="dialog-input" type="text" placeholder="请输入名称" v-model="rulePlatform.platformName"></el-input>
                </el-form-item>
                <el-form-item>
                    <div class="platformTable">
                        <div class="tableItem">
                            <div>平台名称</div><div>操作</div>
                        </div>
                        <div style="height: 220px;overflow-y:scroll;">
                            <div class="tableItem" v-for="(item, index) in platformAll" :key="index">
                                <div>{{item.platformName}}</div><div><el-button type="text" size="small" @click="() => { deletePlatform(item.id) }">删除</el-button></div>
                            </div>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="addPlatformDialog = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => createPlat()">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag  v-loading="loading" element-loading-text="数据较多，拼命加载中..." title="报价单设置" :append-to-body="true" width="530px" :visible.sync="commitDialog" :close-on-click-modal="false">
             <div>确认保存当前更改吗？</div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="commitDialog = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { quotedSettingEdit() }">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag title="取消" :append-to-body="true" width="530px" :visible.sync="resetDialog" :close-on-click-modal="false">
             <div>确认返回报价中心吗？</div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="resetDialog = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { resetBtn() }">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag title="删除平台" :append-to-body="true" width="530px" :visible.sync="deleteDialog" :close-on-click-modal="false">
            <div>确认删除该平台吗？</div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="deleteDialog = false">取 消</el-button>
                <el-button class="common-screen-btn" type="primary" @click="() => { delPlat() }">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog v-dialogDrag title="测试估价" class="evaluation-dialog" :append-to-body="true" width="530px" :visible.sync="evaluationDialog" :close-on-click-modal="false">
            <div>
                <div class="evaluation-item border-blue">
                    <span>协议子账号日咨询量</span>
                    <span><el-input type="number" @mousewheel.native.prevent class="item-input" @change="() => { setMealFun() }" v-model="evaluationForm.accDayConsultation"></el-input></span>
                </div>
                <div class="evaluation-item">
                    <span>基础服务</span>
                    <span></span>
                </div>
                <div class="evaluation-item">
                    <span>平台</span>
                    <span>
                        <el-select class="item-input" @change="() => { setMealFun() }" v-model="evaluationForm.platform" filterable placeholder="请选择">
                            <el-option v-for="(item, key, index) in dictPlatform" :key="index" :label="key" :value="key"></el-option>
                        </el-select>
                    </span>
                </div>
                <div class="evaluation-item">
                    <span>客服类型</span>
                    <span>
                        <el-select class="item-input" @change="() => { setMealFun() }" v-model="evaluationForm.serType" filterable placeholder="请选择">
                            <el-option v-for="(item, index) in serviceTypeList" :key="index" :label="item.remark" :value="item.dataValue"></el-option>
                        </el-select>
                    </span>
                </div>
                <!-- <div class="evaluation-item">
                    <span>协议子账号数量</span>
                    <span><el-input class="item-input" v-model="evaluationForm.accNum"></el-input></span>
                </div> -->
                <div class="evaluation-item">
                    <span>客服人力</span>
                    <span><el-input type="number" @mousewheel.native.prevent class="item-input" @change="() => { setMealFun() }" v-model="evaluationForm.serNum"></el-input></span>
                </div>
                <div class="evaluation-item">
                    <span>客服工作时长系数</span>
                    <span>
                        <el-select class="item-input" @change="() => { setMealFun() }" v-model="evaluationForm.serWorkHour" filterable placeholder="请选择">
                            <el-option v-for="(item, index) in serviceWorkList" :key="index" :label="item.remark" :value="item.dataValue"></el-option>
                        </el-select>
                    </span>
                </div>
                <div class="evaluation-item border-blue">
                    <span>店铺服务天数</span>
                    <span><el-input type="number" @mousewheel.native.prevent class="item-input" @change="() => { setMealFun() }" v-model="evaluationForm.shopDays"></el-input></span>
                </div>
                <!-- <div class="evaluation-item border-blue">
                    <span></span>
                    <span class="sumPrice">
                        <span>￥</span><span>{{(evaluationForm.price1).toFixed(2)}}</span>
                        <el-tooltip class="item" effect="dark" content="【说明】 基础服务报价 = 协议子账号日咨询量 * 客服人力 * 客服类型 * 单个咨询量价格 * 服务天数" placement="bottom">
                            <img src="../../assets/images/question.png" alt="">
                        </el-tooltip>
                    </span>
                </div> -->
                <div class="evaluation-item border-blue">
                    <span>【配置服务内容】</span>
                    <span  style="color:#afafaf;font-size: 12px">
                        售前、售中、售后、附加类按最低标准计算
                        <!-- <el-checkbox v-model="evaluationForm.checked1">售前</el-checkbox>
                        <el-checkbox v-model="evaluationForm.checked2">售后</el-checkbox>
                        <el-checkbox v-model="evaluationForm.checked3">售中</el-checkbox>
                        <el-checkbox v-model="evaluationForm.checked4">附件类</el-checkbox> -->
                    </span>
                </div>
                <!-- <div class="evaluation-item border-blue">
                    <span></span>
                    <span class="sumPrice">
                        <span>￥</span><span>{{(evaluationForm.price2).toFixed(2)}}</span>
                        <el-tooltip class="item" effect="dark" content="【说明】 配置服务内容报价 = 协议子账号日咨询量 * 客服人力*客服类型 * [ 1 + ( 售前 + 售中 + 售后 + 附加类 )] * 单个咨询量价格 * 服务天数" placement="bottom">
                            <img src="../../assets/images/question.png" alt="">
                        </el-tooltip>
                    </span>
                </div> -->
                <div class="evaluation-item">
                    <span>【约定数据标准】</span>
                    <span style="color:#afafaf;font-size: 12px">默认一个店铺数</span>
                </div>
                <div class="evaluation-item border-blue">
                    <span>套餐</span>
                    <span>
                         <el-select class="item-input" v-model="evaluationForm.setMeal" @change="(val) => { setMealFun(val) }" placeholder="请选择">
                            <el-option key="base" label="基础版" value="base"></el-option>
                            <el-option key="register" label="专业版" value="register"></el-option>
                            <el-option key="notice" label="旗舰版" value="notice"></el-option>
                        </el-select>
                    </span>
                </div>
                <!-- <div class="evaluation-item">
                    <span></span>
                    <span class="sumPrice">
                        <span>￥</span><span>{{(evaluationForm.price3).toFixed(2)}}</span>
                        <el-tooltip class="item" effect="dark" content="【说明】 约定数据报价 = 协议子账号日咨询量 * 客服人力 * 客服类型 * { 1 + [ 店铺数 * 平台系数 ]} * 单个咨询量价格 * 服务天数" placement="bottom">
                            <img src="../../assets/images/question.png" alt="">
                        </el-tooltip>
                    </span>
                </div>
                <div class="evaluation-item border-blue" style="height: 14px">
                    <span></span>
                    <span style="color:#afafaf;font-size: 12px">默认一个店铺数</span>
                </div> -->
                <div class="evaluation-item">
                    <span></span>
                    <span class="sumPrice">
                        <span>￥</span><span>{{(evaluationForm.price4).toFixed(2)}}</span>
                        <el-tooltip class="item" effect="dark" content="【说明】 订单报价 = 协议子账号日咨询量 * 客服人力 * 客服类型 * 客服工作时长系数 * { 1 + [( 店铺数 * 淘系平台系数 + 店铺数 * 拼多多平台系数 + 店铺数 * 抖音平台系数 ) + ( 售前 + 售中 + 售后 + 附加类 )]} * 单个咨询量价格 * 服务天数" placement="bottom">
                            <img src="../../assets/images/question.png" alt="">
                        </el-tooltip>
                    </span>
                </div>
                
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button class="common-screen-btn" @click="evaluationDialog = false">取 消</el-button>
                <!-- <el-button class="common-screen-btn" type="primary" @click="() => { delPlat() }">确 定</el-button> -->
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { quotedSettingDetail, quotedSettingEdit, createPlat, delPlat, getPlatList } from "../../service/common.js"
export default {
    data () {
        return {
            checked: false,
            input3: '',
            evaluationDialog: false,
            moreVisib: true,
            loading: false,
            deleteDictId: '',
            resetDialog: false,
            commitDialog: false,
            addPlatformDialog: false,
            deleteDialog: false,
            dictPlatform: {}, // 平台字典值
            dictService: {}, // 服务内容字典
            dictServicePrice: {}, // 客服类型/咨询量价格-字典
            platformAll: [], // 报价平台所有
            serviceTypeList: [],
            serviceWorkList: [],
            rulePlatform: {
                platformName: ''
            },
            rulePlatformRules: { // 校验规则
                platformName: [{ required: true, message: "请填写平台名称", trigger: "change" }]
            },
            evaluationForm: { // 测试估价
                consultationPrice: 0,
                // price1: 0,
                // price2: 0,
                // price3: 0,
                price4: 0
            },
        }
    },
    created() {
        // this.quotedSettingDetail('dictPlatform', '2d8040003f614f358bf4a78be1855a71,ae5ed06130314baa96e9b901b91b461d,f651a40f9eae4903a56031949aa2730e,656b571155f341aeaf0af307c493c72a')
        this.quotedSettingDetail('dictService', '11ea5bc0bb784bab80e2782bb4e1e625,17f63a25cc4644f392fcedfe5201a308,827961b55eeb4cda97616b9357fa4010,597c08d951f445ddb8e004eb0b605627')
        this.quotedSettingDetail('dictServicePrice', '64385b44bfe1474cb5d560196b7ca505')
        // this.quotedSettingDetail('platformAll', '483f73767657412bb287cc8ceda72649')
        this.getPlatList()
    },
    methods: {
        showtestDialog () { // 测试估价-弹窗
            
            let serviceTypeList = [] // 客服类型
            let serviceWorkList = [] // 客服工作时长
            let consultationPrice // 单个咨询量价格
            this.dictServicePrice['报价单配置'].map((item) => {
                if (item.dataName == 'generalCustomerService' || item.dataName == 'goldCustomerService') {
                    serviceTypeList.push(item)
                }
                if (item.dataName == 'eightHour' || item.dataName == 'sixteenHour') {
                    serviceWorkList.push(item)
                }
                if (item.dataName == 'offer') {
                    consultationPrice = item.dataValue
                }
            })
            this.serviceTypeList = serviceTypeList
            this.serviceWorkList = serviceWorkList
            let evaluationForm = {
                accDayConsultation: 100,
                serNum: 1,
                serType: serviceTypeList[1].dataValue || 0,
                serWorkHour: serviceWorkList[1].dataValue || 0,
                platform:  '淘宝',
                // accNum: 1,
                shopDays: 30,
                consultationPrice: consultationPrice, // 单个咨询量价格
                setMeal: 'base',
                // price1: 0,
                // price2: 0,
                // price3: 0,
                price4: 0
            }
            let dictPlatform = {...this.dictPlatform}
            let platformValue = 0
            for (let key in dictPlatform) {
                if (key == evaluationForm.platform) {
                    // let platformValue = dictPlatform[key]
                    dictPlatform[key].map((item) => {
                        if (item.remark == '系数') {
                            platformValue = item.dataValue[evaluationForm.setMeal]
                        }
                    })
                }
            }
            evaluationForm.price4 = Number(evaluationForm.accDayConsultation) * Number(evaluationForm.serNum) * Number(evaluationForm.serType) * Number(evaluationForm.serWorkHour) * (1 + Number(platformValue)) * Number(evaluationForm.consultationPrice) * Number(evaluationForm.shopDays)
            this.evaluationForm = evaluationForm
            this.evaluationDialog = true
        },
        setMealFun () { // 测试股价-修改
            let evaluationForm = {...this.evaluationForm}
            // if (!val && val != 0) {
            //     return this.$message.error('数据不能为空')
            // }
            if ((!evaluationForm.accDayConsultation && evaluationForm.accDayConsultation != 0) || (!evaluationForm.serNum && evaluationForm.serNum != 0) || (!evaluationForm.shopDays && evaluationForm.shopDays != 0) || (!evaluationForm.serType && evaluationForm.serType != 0) || (!evaluationForm.serWorkHour && evaluationForm.serWorkHour != 0)) {
                evaluationForm.price3 = 0
                return this.$message.error('数据不能为空')
            }
            let dictPlatform = {...this.dictPlatform}
            let platformValue = 0
            for (let key in dictPlatform) {
                if (key == evaluationForm.platform) {
                    // let platformValue = dictPlatform[key]
                    dictPlatform[key].map((item) => {
                        if (item.remark == '系数') {
                            platformValue = item.dataValue[evaluationForm.setMeal]
                        }
                    })
                }
            }
     
            evaluationForm.price4 = Number(evaluationForm.accDayConsultation) * Number(evaluationForm.serNum) * Number(evaluationForm.serType) * Number(evaluationForm.serWorkHour) * (1 + Number(platformValue)) * Number(evaluationForm.consultationPrice) * Number(evaluationForm.shopDays)
            this.evaluationForm = evaluationForm
        },
        async delPlat () { // 删除平台
            let res = await delPlat({}, this.deleteDictId)
            if (res.code == 200) {
                this.$message.success('删除成功')
                this.getPlatList()
                this.deleteDialog = false
            }
        },
        async getPlatList () { // 获取平台
            let { data } = await getPlatList()
            let newUuids = []
            data.map((item) => {
                if (item.elementUuid) {
                    newUuids.push(item.elementUuid)
                }
            })
            this.quotedSettingDetail('dictPlatform', newUuids.join(','))
            this.platformAll = data
        },
        addPlatformShow () { // 新增平台弹窗
            this.rulePlatform = {
                platformName: ''
            }
            if (this.$refs["rulePlatform"]) {
                this.$refs["rulePlatform"].resetFields();
            }
            this.addPlatformDialog = true
        },
        createPlat () { // 新增平台
            this.$refs['rulePlatform'].validate(async (valid) => {
                if (valid) { // 发送请求
                    let params = {...this.rulePlatform}
                    params.parentId = 0
                    let res = await createPlat(params)
                    if (res.code == 200) {
                        this.$message.success('添加成功')
                        this.getPlatList()
                        this.addPlatformDialog = false
                    }
                } else {
                    return false;
                }
            });
        },
        deletePlatform (id) { // 删除平台
            this.deleteDictId = id
            this.deleteDialog = true
        },
        resetBtn () {
            this.$router.push('/quoted/quotes')
            // this.quotedSettingDetail('dictService', '11ea5bc0bb784bab80e2782bb4e1e625,17f63a25cc4644f392fcedfe5201a308,827961b55eeb4cda97616b9357fa4010,597c08d951f445ddb8e004eb0b605627')
            // this.quotedSettingDetail('dictServicePrice', '64385b44bfe1474cb5d560196b7ca505')
            // this.getPlatList()
            // this.resetDialog = false
        },
        async quotedSettingEdit () {
            let dictPlatform = {...this.dictPlatform}
            let dictService = {...this.dictService}
            let dictServicePrice = {...this.dictServicePrice}
            let params = Object.assign({}, dictPlatform, dictService, dictServicePrice)
            this.loading = true
            let res = await quotedSettingEdit(params)
            this.loading = false
            if (res.code == 200) {
                this.$message.success("设置成功")
                this.commitDialog = false
            }
        },
        async quotedSettingDetail(type, uuids) {
            let { data } = await quotedSettingDetail({uuids: uuids})
            if (type == 'dictPlatform' || type == 'dictService') {
                for (const key in data) {
                    if(data[key] && data[key].length) {
                        data[key].map((item) => {
                            if (item.dataValue) {
                                item.dataValue = JSON.parse(item.dataValue)
                            }
                        })
                    }
                }
            }
            this[type] = data
        }
    },
}
</script>
<style lang="less" scoped>
.evaluation-dialog {
    /deep/.el-dialog__body {
        padding: 10px 22px;
    }
    .evaluation-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        height: 50px;
        align-items: center;
        .sumPrice {
            display: flex;
            align-items: center;
            span {
                color: #1890FF;
                &:nth-of-type(1) {
                    font-size: 14px;
                }
                &:nth-of-type(2) {
                    font-size: 28px;
                }
            }
            img {
                width: 16px;
                cursor: pointer;
                margin-left: 16px;
            }
        }
        span {
            font-size: 14px;
        }
        .el-checkbox {
            margin-right: 10px;
        }
        /deep/.el-checkbox__label {
            font-size: 12px !important;
        }
        .item-input {
            width: 110px;
            /deep/.el-input__inner {
                height: 30px !important;
                line-height: 28px !important;
            }
        }
    }
}
.border-blue {
    border-bottom: 1px solid #B5D1FF;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.platformTable {
    width: 100%;
    .tableItem {
        width: 100%;
        height: 30px;
        display: flex;
        justify-content: space-between;
        padding: 0 24px;
        align-items: center;
        background: #f7f7f7;
        border-radius: 4px;
        margin-bottom: 16px;
        box-sizing: border-box;
        font-size: 14px;
    }
}
.setting-container {
    text-align: left;
    padding-bottom: 100px;
    .setting-bottom {
        position: fixed;
        width: 100%;
        height: 84px;
        box-sizing: border-box;
        padding-right: 120px;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        bottom: 0;
        right: 0;
        background: #fff;
        border-top: 20px solid #eee;
    }
    .marginTop30 {
        margin-top: 30px;
    }
    .marginRight16 {
        margin-right: 16px;
    }
    .setting-content {
        .testBtn {
            width: 124px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            background: #1890FF;
            border-radius: 4px;
            cursor: pointer;
            color: #fff;
            margin: 24px auto;
        }
        .content-item {
            display: flex;
            overflow-y: scroll;
            margin-top: 30px;
            .item-left {
                width: 216px;
                line-height: 32px;
                padding-right: 24px;
                text-align: right;
                box-sizing: border-box;
            }
            .item-right {
                flex: 1;
                .grey-tip {
                    color: #999;
                    font-size: 14px;
                    margin-left: 16px;
                }
                .seleteMore {
                    width: 100%;
                    display: flex;
                    box-sizing: border-box;
                    padding-left: 600px;
                    align-items: center;
                    margin-top: 24px;
                    cursor: pointer;
                    span {
                        color: #1890FF;
                        font-size: 14px;
                    }
                    img {
                        width: 24px;
                    }
                }
                .platform-item {
                    width: 626px;
                    margin: 48px 48px 0 0;
                    border: 1px solid #1890FF;
                    border-radius: 4px;
                    overflow: hidden;
                    display: inline-block;
                    vertical-align: top;
                    .platform-content {
                        display: flex;
                        flex-direction: column;
                        padding: 16px 0;
                        .platform-row {
                            height: 52px;
                            line-height: 52px;
                            display: flex;
                            justify-content: space-around;
                            .input-blue {
                                
                                /deep/.el-input__inner {
                                    border-color: #1890FF;
                                    background: #f2f9ff ;
                                }
                            }
                            .row-item {
                                width: 20%;
                                &:nth-of-type(1) {
                                    width: 23%;
                                }
                            }
                        }
                    }
                    .platformItem-title {
                        height: 64px;
                        line-height: 64px;
                        border-bottom: 1px solid #1890ff;
                        background: #F2F9FF;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img {
                            width: 24px;
                        }
                        span {
                            font-size: 24px;
                            color: #333;
                            margin-left: 8px;
                        }
                    }
                }
                .platformBtn {
                    width: 124px;
                    height: 40px;
                    line-height: 40px;
                    font-size: 14px;
                    color: #1890FF;
                    background: #F2F9FF;
                    border-radius: 4px;
                    border: 1px solid #1890FF;
                    text-align: center;
                    cursor: pointer;
                }
                .item-input {
                    width: 88px;
                    /deep/.el-input__inner {
                        height: 30px !important;
                        line-height: 28px !important;
                    }
                }
                .input-YUANTIAO {
                    position: relative;
                    &::after {
                        display: block;
                        position: absolute;
                        content: '元/条';
                        top: 0;
                        bottom: 0;
                        right: -48px;
                        font-size: 14px;
                        margin: auto;
                        line-height: 32px;
                    }
                }
                .input-RATIO {
                    position: relative;
                    &::after {
                        display: block;
                        position: absolute;
                        content: '%';
                        height: 30px;
                        top: 0;
                        bottom: 0;
                        right: -22px;
                        font-size: 14px;
                        margin: auto;
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .setting-title {
        height: 75px;
        line-height: 75px;
        background: #F2F9FF;
        width: 100%;
        color: #333;
        padding: 0 95px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #B5D1FF;
        box-sizing: border-box;
        .title {
            font-size: 18px;
        }
        .tip {
            font-size: 14px;
        }
    }
}
</style>